import { get } from 'lodash';
import React from 'react';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';
import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import { LiteraturRow as TableRow } from '../../style/SearchRow';
import Space from '../../style/Space';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';

import formatZeit from '../../service/formatZeit';
import ownSlice from '../../service/sliceSearch';

import { FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchLiteratur = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('short.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Zitierkürzel:"
                            name="short"
                            value={get(search, 'short') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Autor"
                            api="Zitiername"
                            value={get(search, 'autor') || ''}
                            name="autor"
                            searchParam="nichtAuffuehren.literatur.v=false"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('autor.v')}
                            direction={'autor.v' === sort ? !asc : false}
                            active={'autor.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Titel:"
                            name="titel"
                            value={get(search, 'titel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('titel.v')}
                            direction={'titel.v' === sort ? !asc : false}
                            active={'titel.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Jahr"
                            name="jahr.am.j"
                            value={get(search, 'jahr.am.j')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('jahr.am.j.v')}
                            direction={'jahr.am.j.v' === sort ? !asc : false}
                            active={'jahr.am.j.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Reihe"
                            name="reihe.titel"
                            value={get(search, 'reihe.titel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('reihe.titel.v')}
                            direction={'reihe.titel.v' === sort ? !asc : false}
                            active={'reihe.titel.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="GA"
                            name="gesamtaufnahme"
                            value={get(search, 'gesamtaufnahme')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('gesamtaufnahme.v')}
                            direction={'gesamtaufnahme.v' === sort ? !asc : false}
                            active={'gesamtaufnahme.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Standort"
                            name="exemplar.standort"
                            value={get(search, 'exemplar.standort') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('exemplar.standort.v')}
                            direction={'exemplar.standort.v' === sort ? !asc : false}
                            active={'exemplar.standort.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Signatur"
                            name="exemplar.signatur"
                            value={get(search, 'exemplar.signatur') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('exemplar.signatur.v')}
                            direction={'exemplar.signatur.v' === sort ? !asc : false}
                            active={'exemplar.signatur.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items && items.length < 1 && !loading && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items && items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/literatur/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <Cell block>
                                {item.autor && item.autor.map((verfasser, i) => (
                                    <Link
                                        key={i}
                                        to={'/edit/Zitiername/' + get(verfasser, 'v._id')}
                                    >
                                        {get(verfasser, 'v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell>{item.titel && item.titel.v}</Cell>
                            <Cell>{item.jahr && `${formatZeit(get(item, 'jahr'))}`}</Cell>
                            <Cell>{item.reihe && item.reihe.titel && item.reihe.titel.v}&nbsp;{item.reihe && item.reihe.nummer && item.reihe.nummer.v}</Cell>
							<Cell>{item.gesamtaufnahme && item.gesamtaufnahme.v ? '✓' : ''}</Cell>
							<Cell>
                                {item.exemplar && item.exemplar[0] && item.exemplar[0].standort && item.exemplar.map(e => {
                                    return (
                                    <>
                                        {get(e, 'standort.v') ? (
                                        	(get(e, 'standort.v').includes('http') || 
												get(e, 'standort.v').includes('urn:')) ? (
	                                                <LinkExtern
	                                                    href={get(e, 'standort.v')}
	                                                    target="blank"
	                                                >
	                                                    <WebGlobeSVG />
	                                                </LinkExtern>
	                                        	) : (
	                                                ownSlice(get(e, 'standort.v'), 15)
	                                            )
										) : (<></>)}
                                        <Space />
                                    </>
                                    );
                                })}
                            </Cell>
							<Cell>
                                {item.exemplar && item.exemplar[0] && item.exemplar[0].signatur && item.exemplar[0].signatur.v && item.exemplar.map(e => {
                                    return (
                                        <>
                                            {get(e, 'signatur.v') && get(e, 'signatur.v')}
                                            <Space />
                                        </>
                                    );
                                })}
                            </Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/literatur/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="literatur" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchLiteratur);
