import React from 'react';
import styled from 'styled-components';
import Container from '../style/Container';
import ItemContainer from '../style/ItemContainer';
import Area from '../style/Area';
import Row from '../style/Row';
import Link from '../style/Link';
import { blue, grey, ultraLightGrey } from '../style/colors';
import TextblockArea from '../style/TextblockArea';

const Tiles = styled('div')`
    display: flex;
	align-items: center;
	justify-content: center;
    max-width: 100%;
    height: 100%;
`;

const Text = styled('div')`
	text-align: justify;
	justify-content: center;
	font-size: 16px;
    max-width: 100%;
    height: 100%;
	line-height: 1.5;
`;

const ImageLinkLabel = styled('div')`
    height: 1.5em;
    font-weight: 500;
    font-size: 14px;
	padding-top: 8px;
    text-transform: uppercase;
	//background-color: #F7F7F7;
    color: ${blue};
    &:hover {
        color: ${grey};
    }
`;

const ImageLinkHeader = styled('div')`
    align-items: center;
	justify-content: center;
	text-align: center;
	margin-left: -9px;
	margin-top: -7px;
	border-bottom: 1px inset ${ultraLightGrey};
	background-color: rgba(0,0,0,.03);
    width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const ImageLinkBody = styled('div')`
    align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 10px;
	background-color: ${props => (props.color ? props.color : '')};
    width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

class ImageLink extends React.Component {
    render() {
        const { src, label, href, target } = this.props;
        return (
				<Link to={href} target={target}>
					<Area>
	                    <ImageLinkHeader height="29px" width="109%">
							<ImageLinkLabel to={href} >
		                        {label}
		                    </ImageLinkLabel>
	                    </ImageLinkHeader>
	                    <ImageLinkBody height="142px" width="195px" >
	                        <img src={src} width="100%" alt="" />
						</ImageLinkBody>
	                </Area>
				</Link>
        );
    }
}

class Personen extends React.Component {
    render() {
        return (
			<>      
				<Tiles>
                    <Container>
							<br/><br/><br/><br/>							
							<ItemContainer>
		                        <Row center>		                            
									<ImageLink
		                                src="andreaeklein.jpg"
		                                label="Südwestdt. Theologen"
		                                href="/filter/hauptpersonen"
										target="_self"
		                            />   	       
									<ImageLink
		                                src="heshusiusklein.jpg"
		                                label="Korrespondenten"
		                                href="/filter/korrespondenten"
										target="_self"
		                            />
									<ImageLink
										src="searchPerson.jpg"
										label="Personensuche"
										href="/filter/person"
										target="_self"
									/>
							        <ImageLink
		                                src="erweitertPerson.jpg"
		                                label="Erweiterte Suche"
		                                href="/erweitert/person"
										target="_self"
		                            />
		                        </Row>
							</ItemContainer>
                    </Container>
                </Tiles>		
                <Text>
                    <Container>						
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
			                Die Datenbank erschließt die Briefe aller führenden Theologen und kirchenleitenden Persönlichkeiten der
			                Kurpfalz, Württembergs und Straßburgs und deren Korrespondenzpartner in den Jahren von 1550 bis 1620. 
			                Es werden Theologieprofessoren der Universitäten Heidelberg und Tübingen sowie der Straßburger Akademie, ferner 
			                die territorial oder kirchengeschichtlich bedeutsamen Superintendenten und Hofprediger in dem betreffenden 
			                Zeitraum berücksichtigt. Es handelt sich um ca. 200 Theologen, deren Korrespondenznetzwerke 
			                rekonstruiert werden. Die Briefwechsel dieser Personen werden jeweils vollständig aufgenommen, inklusive der Briefe,
			                die sie außerhalb ihrer südwestdeutschen Tätigkeiten verfassten oder erhielten. Über die Suchfunktionen sind neben 
			                den Korrespondenzpartnern auch die in den Briefen erwähnten Personen auffindbar. 
							</TextblockArea>
                        </Row>
                	</Container>
            	</Text>
			</>
        );
    }
}

export default Personen;
