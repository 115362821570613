import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get as getLodash } from "lodash";
import { changeItem, resetItem } from '../../store/actions/item';
import { changeSort, changeSortAndLoad, resetSearch, getDataFromApi, resetPage, setLimit } from '../../store/actions/search';
import { addNotification } from '../../store/actions/notificationActions';

import SearchFooter from '../../components/SearchFooter';

import Area from '../../style/Area';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { defaultTextColor } from '../../style/colors';
import Button from '../../style/Button';
import Cell from '../../style/SearchCell';
import Container from '../../style/Container';
import Link from '../../style/Link';
import Loading from '../../components/Loading';
import OpenSVG from '../../SVG/Open';
import Row from '../../style/Row';

import { FastSearch, Input } from '../../inputs/InputsWithLabelFocus';

const SearchBody = styled('div')`	
 	display: flex;
 	align-items: center;
  	justify-content: center;
`;

const SearchTable = styled('div')`	
	display: table;
  	align-items: center;
  	justify-content: center;
	width: 900px;
`;

const SearchRowArea = styled(Area)`
    margin-top: 5px;
	margin-bottom: 6px;
	padding: 6px 8px;
	max-width: 100%;
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const TableRow = styled(SearchRowArea)`
    grid-template-columns: 2em 1fr 2fr 1fr 1fr 2em;
    //                        short|alt|alt|typ
    display: grid;
`;

class SearchSache extends React.Component {
       state = {
	            suche: this.props.match.url.split('/')[2].toLowerCase(),
	        }
	
        onKeyPressed = ({ key }) => {
            if (key === "Enter") this.props.resetPage()
        };

        reset = () => {
            this.props.resetItem();
            this.props.resetSearch();
        };
	
        componentWillUnmount() {
           this.reset();
        };
        
        componentDidMount() {
            this.props.changeSort('short.v');
        }
        
        render() {
            const { items, loading, count, sort, asc } = this.props;
            const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
            return (
                <SearchBody onKeyDown={this.onKeyPressed}>
                    <SearchTable>
                        <Container>
	                        <Row>
			                	<TableRow>
				                    <Cell block>{null}</Cell>
				                    <Cell>
				                        <Input noMeta width="100%" label="Sachbegriff" name="long" />
				                        {count !== 0 && (
					                        <ArrowTopDown
					                            onClick={() => this.props.changeSortAndLoad('short.v')}
					                            direction={'short.v' === sort ? !asc : false}
					                            active={'short.v' === sort}
					                        />
				                        )}
				                    </Cell>
				                    <Cell>
				                    	{loading === 0 && count === 0 && (
											!englroute ? (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Suchen
						                        </Button>
					                        ) : (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Search
						                        </Button>
											)
										)}
										{loading === 0 && count !== 0 && (
											!englroute ? (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	aktualisieren
						                        </Button>
					                        ) : (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Search
						                        </Button>
											)
										)}
					                    {loading === 0 && count !== 0 && (	
											<>
											{!englroute ? (
												<Link to={`/filter/sache`} target="_self" color={defaultTextColor}>
													<Button>
						                                zurücksetzen
						                            </Button>
												</Link>
											) : (
												<Link to={`/filter/sache/engl`} target="_self" color={defaultTextColor}>
													<Button>
						                                Reset
						                            </Button>
												</Link>
											)}
											</>
										)}
									</Cell>
									<Cell>
										{!englroute ? (
											<Link to={`/ansicht/sache/640859dd459afb2f0481f2a3`} target="_self" color={defaultTextColor}>
												<Button>
					                                Zeige Sachregister
					                            </Button>
											</Link>
										) : (
											<Link to={`/ansicht/sache/640859dd459afb2f0481f2a3`} target="_self" color={defaultTextColor}>
												<Button>
					                                Show structured index
					                            </Button>
											</Link>
										)}
									</Cell>
				                    <Cell>
				                        <FastSearch
				                            noMeta
				                            width="100%"
				                            label="Sachgruppe filtern"
				                            api="Sachgruppe"
				                            name="sachgruppeName"
											placeholder="z.B. Bibelstelle"
											dontWantID
				                        />
				                        {count ? (
					                        <ArrowTopDown
					                            onClick={() => this.props.changeSortAndLoad('typ.v')}
					                            direction={'typ.v' === sort ? !asc : false}
					                            active={'typ.v' === sort}
					                        />
				                        ) : (
											<></>
										)}
				                    </Cell>
				                    <Cell block>{null}</Cell>
			                    </TableRow>
			                    {/*
								<>
									{!englroute && (
										<Link to={`/suche/sache`} target="_self" color={defaultTextColor}>
											<Button>
		                                        Zur Suche
		                                    </Button>
										</Link>
									)}
									{englroute && (
										<Link to={`/suche/sache/engl`} target="_self" color={defaultTextColor}>
											<Button>
		                                      	 Go to search mode
		                                    </Button>
										</Link>
									)}
								</>
								*/}
						 	</Row>
			                <Loading loading={loading}>
			                    {items.map((item, i) => (
			                        <TableRow key={i}>
			                        	<Cell>
				                            <Link to={`/ansicht/sache/${item._id}`} target="_blank">
				                                <OpenSVG />
				                            </Link>
				                        </Cell>
			                            <Cell>{item.short && item.short.v}</Cell>				                            
			                            <Cell block>
			                                {item.alternativen && item.alternativen.map((object) => (
			                                   <div>{getLodash(object, 'text.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell block>
			                                {item.alternativen && item.alternativen.map((object) => (
			                                   <div>
			                                   		{getLodash(object, 'sache.v.short.v') && (
												   		<>
												   			<>➔ </>
												   			<Link to={`/ansicht/sache/${item._id}`} target='_blank'>{getLodash(object, 'sache.v.short.v')}</Link>
											   			</>
													)}
												</div>
			                                ))}
			                            </Cell>
			                            <Cell>{item.typ && item.typ.v && item.typ.v.short.v}</Cell>		
			                            <Cell>{null}</Cell>
			                        </TableRow>
			                    ))}
			                </Loading>
							<SearchFooter startSearch={getDataFromApi}/>                            
                        </Container>
                    </SearchTable>
                </SearchBody>
            );
        };
	}
	
   const mapStateToProps = (state) => ({
        items: state.search.items,
        sort: state.search.sort,
        asc: state.search.asc,
        loading: state.search.loading,
        count: state.search.count
    });

    const mapDispatchToProps = {
        changeSortAndLoad,
        changeSort,
        resetSearch,
        getDataFromApi,
        resetPage,
        setLimit,
        changeItem,
        resetItem,
        addNotification
    };
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchSache);