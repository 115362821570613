import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { LateinRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import { Input } from '../../inputs/InputsWithLabelFocus';

const SearchLatein = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('wort.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Wort"
                            name="wort"
                            value={get(search, 'wort') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('wort.v')}
                            direction={'wort.v' === sort ? !asc : false}
                            active={'wort.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Übersetzung"
                            name="uebersetzung"
                            value={get(search, 'uebersetzung') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('uebersetzung.v')}
                            direction={'uebersetzung.v' === sort ? !asc : false}
                            active={'uebersetzung.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && !loading && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/latein/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.wort && item.wort.v}</Cell>
                            <Cell>{item.uebersetzung && item.uebersetzung.v}</Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/latein/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="latein" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchLatein);
