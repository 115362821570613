import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Body from './Body';

//components, container
import AdminRoute from './client/components/AdminRoute';
//import NavAdvSearch from './client/components/NavAdvSearch';
import NavBand from './client/components/NavBand';
import NavInternAttributesSearch from './client/components/NavAttributesSearch';
import NavInternOpSearch from './client/components/NavOpSearch';
import NavList from './client/components/NavList';
import NavSearch from './client/components/NavSearch';
import NavUser from './client/components/NavUser';
import PrivateRoute from './client/components/PrivateRoute';
import NotificationContainer from './client/container/Notification';

//einzeln
import EmptyList from './client/routes/EmptyList';
import ForcedLogout from './client/routes/ForcedLogout';
import Login from './client/routes/Login';
import Notfound from './client/routes/PageNotFound';
import RightsNotSufficient from './client/routes/RightsNotSufficient';
import Start from './client/routes/Start';

//log, ref, redirect
import LogView from './client/routes/log/LogView';
import Ref from './client/routes/ref/Ref';
import Zitiername from './client/routes/redirect/Zitiername';

//edit
import Amt from './client/routes/edit/Amt';
import Archiv from './client/routes/edit/Archiv';
import Brief from './client/routes/edit/Brief';
import Briefdruck from './client/routes/edit/Briefdruck';
import Drucke from './client/routes/edit/Druck';
import Handschriften from './client/routes/edit/Briefhandschrift';
import Latein from './client/routes/edit/Latein';
import Literatur from './client/routes/edit/Literatur';
import Mitarbeiter from './client/routes/edit/Mitarbeiter';
import Ort from './client/routes/edit/Ort';
import Person from './client/routes/edit/Person';
import Sache from './client/routes/edit/Sache';
import Signatur from './client/routes/edit/Signatur';

//listen
import Band from './client/routes/edit/Listen/Band';
import Beziehung from './client/routes/edit/Listen/Beziehung';
import Geo from './client/routes/edit/Listen/Geo';
import Konfession from './client/routes/edit/Listen/Konfession';
import Medium from './client/routes/edit/Listen/Medium';
import Profession from './client/routes/edit/Listen/Profession';
import Sachgruppe from './client/routes/edit/Listen/Sachgruppe';
import Sprache from './client/routes/edit/Listen/Sprache';
import Textsorte from './client/routes/edit/Listen/Textsorte';
import UebForm from './client/routes/edit/Listen/UebForm';
import Verzeichnungsstufe from './client/routes/edit/Listen/Verzeichnungsstufe';

//user
import Favoriten from './client/routes/user/Favoriten';
import User from './client/routes/user/User';
import zuletztBearbeitet from './client/routes/user/ZuletztBearbeitet';

//adv, attributes, op
import AdvSearch from './client/routes/search/adv/AdvSearch';
import Attributes from './client/routes/search/Attributes';
import InternAttributesSearch from './client/routes/search/InternAttributesSearch';
import InternOpSearch from './client/routes/search/operator/InternOpSearch';

//search
import SearchAmt from './client/routes/search/SearchAmt';
import SearchArchiv from './client/routes/search/SearchArchiv';
import SearchBrief from './client/routes/search/SearchBrief';
import SearchBriefdruck from './client/routes/search/SearchBriefdruck';
import SearchDrucke from './client/routes/search/SearchDruck';
import SearchHandschriften from './client/routes/search/SearchBriefhandschrift';
import SearchKurpfalzI from './client/routes/search/SearchKurpfalzI';
import SearchStrassburgI from './client/routes/search/SearchStrassburgI';
import SearchWuerttembergI from './client/routes/search/SearchWuerttembergI';
import SearchWuerttembergII from './client/routes/search/SearchWuerttembergII';
import SearchHauptpersonen from './client/routes/search/SearchHauptpersonen';
import SearchLatein from './client/routes/search/SearchLatein';
import SearchLiteratur from './client/routes/search/SearchLiteratur';
import SearchMitarbeiter from './client/routes/search/SearchMitarbeiter';
import SearchOrt from './client/routes/search/SearchOrt';
import SearchPerson from './client/routes/search/SearchPerson';
import SearchZitiername from './client/routes/search/SearchZitiername';
import SearchSache from './client/routes/search/SearchSache';
import SearchSignatur from './client/routes/search/SearchSignatur';

//search listen
import SearchBand from './client/routes/search/Listen/SearchBand';
import SearchBeziehung from './client/routes/search/Listen/SearchBeziehung';
import SearchGeo from './client/routes/search/Listen/SearchGeo';
import SearchKonfession from './client/routes/search/Listen/SearchKonfession';
import SearchMedium from './client/routes/search/Listen/SearchMedium';
import SearchProfession from './client/routes/search/Listen/SearchProfession';
import SearchSachgruppe from './client/routes/search/Listen/SearchSachgruppe';
import SearchSprache from './client/routes/search/Listen/SearchSprache';
import SearchStufe from './client/routes/search/Listen/SearchStufe';
import SearchUebForm from './client/routes/search/Listen/SearchUebForm';
import SearchTextsorte from './client/routes/search/Listen/SearchTextsorte';

//style
import ScrollContainer from './client/style/ScrollContainer';

//extern components, container
import Header from './clientExtern/components/Header';
import Subheader from './clientExtern/components/Subheader';
import Footer from './clientExtern/container/Footer';

//extern einzeln
import Welcome from './clientExtern/routes/Welcome';
import WelcomeEngl from './clientExtern/routes/english/englWelcome';
import Instructions from './clientExtern/routes/english/englInstructions';
import Benutzungshinweise from './clientExtern/routes/Benutzungshinweise';
import Bildnachweise from './clientExtern/routes/Bildnachweise';
import Editionsbaende from './clientExtern/routes/Editionsbaende';
import BriefDesMonats from './clientExtern/routes/BriefDesMonats';
import Karte from './clientExtern/routes/Karte';
import Personen from './clientExtern/routes/Personen';
import Orte from './clientExtern/routes/Orte';
import Sachen from './clientExtern/routes/Sachen';
import Briefe from './clientExtern/routes/Briefe';
			
//extern redirect
import PermaLinkBrief from './clientExtern/routes/redirect/PermaLinkBrief';
import PermaLinkPerson from './clientExtern/routes/redirect/PermaLinkPerson';
import ShortRedirectPerson from './clientExtern/routes/redirect/ShortRedirectPerson';
import PermaLinkSache from './clientExtern/routes/redirect/PermaLinkSache';
import ZitiernameView from './clientExtern/routes/redirect/ZitiernameView';

//extern search
import ExternOpSearch from './clientExtern/routes/publicSearch/operator/ExternOpSearch';
import ExternAttributesSearch from './clientExtern/routes/publicSearch/ExternAttributesSearch';
import PublicSearchBrief from './clientExtern/routes/publicSearch/PublicSearchBrief';
import PublicSearchOrt from './clientExtern/routes/publicSearch/PublicSearchOrt';
import PublicSearchSache from './clientExtern/routes/publicSearch/PublicSearchSache';
import PublicSearchEreignisse from './clientExtern/routes/publicSearch/PublicSearchEreignisse';
import PublicSearchWerke from './clientExtern/routes/publicSearch/PublicSearchWerke';
import PublicSearchPerson from './clientExtern/routes/publicSearch/PublicSearchPerson';
import PublicSearchHauptpersonen from './clientExtern/routes/publicSearch/PublicSearchHauptpersonen';
import PublicSearchKorrespondenten from './clientExtern/routes/publicSearch/PublicSearchKorrespondenten';

//extern view
import PersonPublicUI from './clientExtern/routes/publicView/PersonPublicUI';
import BriefPublicUI from './clientExtern/routes/publicView/BriefPublicUI';
import SachePublicUI from './clientExtern/routes/publicView/SachePublicUI';
import OrtPublicUI from './clientExtern/routes/publicView/OrtPublicUI';
import LiteraturPublicUI from './clientExtern/routes/publicView/LiteraturPublicUI';
import ArchivPublicUI from './clientExtern/routes/publicView/ArchivPublicUI';
import BriefhandschriftPublicUI from './clientExtern/routes/publicView/BriefhandschriftPublicUI';
import BriefdruckPublicUI from './clientExtern/routes/publicView/BriefdruckPublicUI';
import { DefaultMap } from './clientExtern/maps/maps';

export default () => (
    <>
        <ScrollContainer>
			<Body>
				<Header />
				<Subheader />
		        <PrivateRoute path="/search" component={NavSearch} />
		        <PrivateRoute path="/operator" component={NavInternOpSearch} />
		        <PrivateRoute path="/attributes" component={NavInternAttributesSearch} />
		        <PrivateRoute path="/list" component={NavList} />
				<PrivateRoute path="/band" component={NavBand} />
		        <PrivateRoute path="/user" component={NavUser} />
                <Switch>
                    <Route exact path="/" component={Welcome} />
					<Route exact path="/engl" component={WelcomeEngl} />
					<Route exact path="/benutzungshinweise" component={Benutzungshinweise} />
					<Route exact path="/instructions/engl" component={Instructions} />
					<Route exact path="/briefdesmonats" component={BriefDesMonats} />
					<Route exact path="/letterofthemonth/engl" component={BriefDesMonats} />
					<Route exact path="/editionsbaende" component={Editionsbaende} />
					<Route exact path="/editions/engl" component={Editionsbaende} />
					<Route exact path="/bildnachweise" component={Bildnachweise} />
					 {/*<Route exact path="/karte" component={Karte} />*/}
					<Route exact path="/karte" component={DefaultMap} />
					<Route exact path="/karte/engl" component={DefaultMap} />
					<Route exact path="/findOrt" component={Karte} />
                    <Route exact path="/rightsNotSufficient" component={RightsNotSufficient} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/login/forceLogout" component={ForcedLogout} />

                    {/*redirect permalink*/}
                    <Route exact path="/brief/:id" component={PermaLinkBrief} />
					<Route exact path="/person/:id" component={PermaLinkPerson} />
					<Route exact path="/sache/:id" component={PermaLinkSache} />
					<Route exact path="/person/name/:short" component={ShortRedirectPerson} />

					<Route exact path="/personen" component={Personen} />
					<Route exact path="/orte" component={Orte} />
					<Route exact path="/sachen" component={Sachen} />
					<Route exact path="/briefe" component={Briefe} />

                    <PrivateRoute exact path="/user" component={EmptyList} />
                    <PrivateRoute exact path="/user/mitarbeiter/:id" component={User} />
                    <PrivateRoute exact path="/user/favoriten/:id" component={Favoriten} />
                    <PrivateRoute exact path="/user/:type/:id" component={zuletztBearbeitet} />

                    <Route exact path="/filter" component={EmptyList} />
                    <Route exact path="/filter/brief" component={PublicSearchBrief} />
					<Route exact path="/filter/brief/engl" component={PublicSearchBrief} />
                    <Route exact path="/filter/person" component={PublicSearchPerson}/>
                    <Route exact path="/filter/hauptpersonen" component={PublicSearchHauptpersonen} />
					<Route exact path="/filter/korrespondenten" component={PublicSearchKorrespondenten} />	
					<Route exact path="/filter/person/engl" component={PublicSearchPerson}/>
                    <Route exact path="/filter/sache" component={PublicSearchSache} />
					<Route exact path="/filter/ereignisse" component={PublicSearchEreignisse} />
					<Route exact path="/filter/werke" component={PublicSearchWerke} />
					<Route exact path="/filter/sache/engl" component={PublicSearchSache} />
                    <Route exact path="/filter/ort" component={PublicSearchOrt} />
					<Route exact path="/filter/ort/engl" component={PublicSearchOrt} />
					
                    <Route exact path="/ansicht/ort/:id" component={OrtPublicUI} />
					<Route exact path="/ansicht/ort/:id/engl" component={OrtPublicUI} />
                    <Route exact path="/ansicht/brief/:id" component={BriefPublicUI} />
					<Route exact path="/ansicht/brief/:id/engl" component={BriefPublicUI} />
                    <Route exact path="/ansicht/sache/:id" component={SachePublicUI} />
					<Route exact path="/ansicht/sache/:id/engl" component={SachePublicUI} />
					<Route exact path="/ansicht/literatur/:id" component={LiteraturPublicUI} />
					<Route exact path="/ansicht/literatur/:id/engl" component={LiteraturPublicUI} />
					<Route exact path="/ansicht/archiv/:id" component={ArchivPublicUI} />
					<Route exact path="/ansicht/archiv/:id/engl" component={ArchivPublicUI} />
					<Route exact path="/ansicht/briefhandschrift/:id" component={BriefhandschriftPublicUI} />
					<Route exact path="/ansicht/briefhandschrift/:id/engl" component={BriefhandschriftPublicUI} />
					<Route exact path="/ansicht/briefdruck/:id" component={BriefdruckPublicUI} />
					<Route exact path="/ansicht/briefdruck/:id/engl" component={BriefdruckPublicUI} />
                    <Route exact path="/ansicht/person/:id" component={PersonPublicUI} />
					<Route exact path="/ansicht/person/:id/engl" component={PersonPublicUI} />
					<Route exact path="/ansicht/zitiername/:id?" component={ZitiernameView} />
					<Route exact path="/ansicht/zitiername/:id?/engl" component={ZitiernameView} />

                    <PrivateRoute exact path="/search" component={Start} />
                    <PrivateRoute exact path="/search/brief/" component={SearchBrief} />
                    <PrivateRoute exact path="/search/archiv/" component={SearchArchiv} />
                    <PrivateRoute exact path="/search/briefhandschrift" component={SearchHandschriften} />
                    <PrivateRoute exact path="/search/druck/" component={SearchDrucke} />
                    <PrivateRoute exact path="/search/briefdruck/" component={SearchBriefdruck} />
                    <PrivateRoute exact path="/search/literatur/" component={SearchLiteratur} />
                    <PrivateRoute exact path="/search/zitiername/" component={SearchZitiername} />
                    <PrivateRoute exact path="/search/person/person/" component={SearchPerson} />                    
                    <PrivateRoute exact path="/search/hauptpersonen/" component={SearchHauptpersonen} />
                    <PrivateRoute exact path="/search/ort/" component={SearchOrt} />
                    <PrivateRoute exact path="/search/signatur/" component={SearchSignatur} />
                    <PrivateRoute exact path="/search/sache/" component={SearchSache} />
					<PrivateRoute exact path="/list/latein/" component={SearchLatein} />					
					<PrivateRoute exact path="/band" component={EmptyList} />
					<PrivateRoute exact path="/band/kurpfalz1/" component={SearchKurpfalzI} />
					<PrivateRoute exact path="/band/wuerttemberg1/" component={SearchWuerttembergI} />
					<PrivateRoute exact path="/band/wuerttemberg2/" component={SearchWuerttembergII} />
					<PrivateRoute exact path="/band/strassburg1/" component={SearchStrassburgI} />

                    <PrivateRoute exact path="/advSearch" component={EmptyList} />
                    <PrivateRoute exact path="/advSearch/:type" component={AdvSearch} />

                    <Route exact path="/erweitert" component={EmptyList} />
                    <Route exact path="/erweitert/:type(brief|person)" component={ExternOpSearch} />
					<Route exact path="/erweitert/:type(brief|person)/engl" component={ExternOpSearch} />
					 <Route exact path="/suche" component={EmptyList} />	
                    {/*Using key property to force React to remount component*/}	
                    <Route exact path="/suche/brief" key="brief" component={ExternAttributesSearch}	/>
					<Route exact path="/suche/brief/engl" key="brief" component={ExternAttributesSearch}/>		
                    <Route exact path="/suche/person" key="person" component={ExternAttributesSearch} />
					<Route exact path="/suche/person/engl" key="person" component={ExternAttributesSearch} />
                    <Route exact path="/suche/ort" key="ort" component={ExternAttributesSearch}	/>
					<Route exact path="/suche/ort/engl"	key="ort" component={ExternAttributesSearch} />	
                    <Route exact path="/suche/sache" key="sache" component={ExternAttributesSearch}	/>
					<Route exact path="/suche/sache/engl" key="sache" component={ExternAttributesSearch} />
                    <PrivateRoute exact path="/operator" component={Attributes} />
                    <PrivateRoute exact path="/operator/:type(brief|person|briefhandschrift|ort|sache)" component={InternOpSearch} />
                    <PrivateRoute exact path="/attributes" component={Attributes} />
                    {/*Using key property to force React to remount component*/}
                    <PrivateRoute exact path="/attributes/brief" key="brief" component={InternAttributesSearch} />
					<PrivateRoute exact path="/attributes/briefhandschrift" key="briefhandschrift" component={InternAttributesSearch} />
                    <PrivateRoute exact path="/attributes/person" key="person" component={InternAttributesSearch} />
                    <PrivateRoute exact path="/attributes/ort" key="ort" component={InternAttributesSearch} />
                    <PrivateRoute exact path="/attributes/sache" key="sache" component={InternAttributesSearch} />
                    <PrivateRoute exact path="/list" component={EmptyList} />
                    <PrivateRoute exact path="/list/amt" component={SearchAmt} />
                    <PrivateRoute exact path="/list/sprache" component={SearchSprache} />
					<PrivateRoute exact path="/list/band" component={SearchBand} />
                    <PrivateRoute exact path="/list/beziehung" component={SearchBeziehung} />
                    <PrivateRoute exact path="/list/konfession" component={SearchKonfession} />
                    <PrivateRoute exact path="/list/profession" component={SearchProfession} />
                    <PrivateRoute exact path="/list/geo" component={SearchGeo} />
                    <PrivateRoute exact path="/list/sachgruppe" component={SearchSachgruppe} />
                    <PrivateRoute exact path="/list/verzeichnungsstufe" component={SearchStufe} />
                    <PrivateRoute exact path="/list/uebForm" component={SearchUebForm} />
                    <PrivateRoute exact path="/list/textsorte" component={SearchTextsorte} />
                    <PrivateRoute exact path="/list/medium" component={SearchMedium} />
                    <PrivateRoute exact path="/list/mitarbeiter/" component={SearchMitarbeiter} />

                    <PrivateRoute exact path="/edit/brief/:id?" component={Brief} />
                    <PrivateRoute exact path="/edit/archiv/:id?" component={Archiv} />
                    <PrivateRoute exact path="/edit/druck/:id?" component={Drucke} />
                    <PrivateRoute exact path="/edit/briefhandschrift/:id?" component={Handschriften}/>
                    <PrivateRoute exact path="/edit/literatur/:id?" component={Literatur} />
                    <PrivateRoute exact path="/edit/person/:id?" component={Person} />
					<PrivateRoute exact path="/edit/zitiername/:id?" component={Zitiername} />
                    <PrivateRoute exact path="/edit/ort/:id?" component={Ort} />
                    <PrivateRoute exact path="/edit/briefdruck/:id?" component={Briefdruck} />
                    <PrivateRoute exact path="/edit/signatur/:id?" component={Signatur} />
                    <PrivateRoute exact path="/edit/sache/:id?" component={Sache} />
                    <PrivateRoute exact path="/edit/amt/:id?" component={Amt} />

                    <AdminRoute exact path="/edit/sprache/:id?" component={Sprache} />
					<AdminRoute exact path="/edit/band/:id?" component={Band} />
                    <AdminRoute exact path="/edit/beziehung/:id?" component={Beziehung} />
                    <AdminRoute exact path="/edit/konfession/:id?" component={Konfession} />
                    <AdminRoute exact path="/edit/profession/:id?" component={Profession} />
                    <AdminRoute exact path="/edit/geo/:id?" component={Geo} />
                    <AdminRoute exact path="/edit/sachgruppe/:id?" component={Sachgruppe} />
                    <AdminRoute exact path="/edit/verzeichnungsstufe/:id?" component={Verzeichnungsstufe} />
                    <AdminRoute exact path="/edit/uebForm/:id?" component={UebForm} />
                    <AdminRoute exact path="/edit/textsorte/:id?" component={Textsorte} />
                    <AdminRoute exact path="/edit/medium/:id?" component={Medium} />
                    <AdminRoute exact path="/edit/mitarbeiter/:id?" component={Mitarbeiter} />
					<AdminRoute exact path="/edit/latein/:id?" component={Latein} />
                    <PrivateRoute exact path="/log/:type/:id" component={LogView} />

                    <AdminRoute exact path="/ref/:model/:id" component={Ref} />

                    <Redirect from="/search/person" to="/search/person/person" />

                    <Route path="*" component={Notfound} />
                </Switch>
            </Body>
			<Footer />
        </ScrollContainer>
        <NotificationContainer />
    </>
);
