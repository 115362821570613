import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeItem, resetItem } from '../store/actions/item';
import { changeSort,  changeSortAndLoad, resetSearch, getDataFromApi, resetPage, setLimit } from '../store/actions/search';
import { addNotification } from '../store/actions/notificationActions';

import SearchFooter from '../components/SearchFooter';

import Button from '../style/Button';
import Row from '../style/Row';
import H2 from '../style/H2';
import ScrollContainer from '../style/ScrollContainer';
import Container from '../style/Container';
import ItemContainer from '../style/ItemContainer';
import { defaultTextColor } from '../style/colors';
import Link from '../style/Link';

const typeParser = (type) => {
    switch (type.toLowerCase()) {
        case 'geo':
            type = 'Geographische Kategorie';
            break;
        case 'uebform':
            type = 'Überlieferungsform';
            break;
        default:
            type = type.charAt(0).toUpperCase() + type.slice(1);
    }
    return 'Suche ' + type;
};

const typeSlicer = (url) => {
    const splitURL = url.split('/').map(s => s.toLowerCase());
    if (splitURL[2] === 'person') {
        if (splitURL[1] === 'search') {
            return splitURL[3];
        }
    }
    else if (splitURL[2] === 'strassburg1') {
		return 'brief';
	}
	else if (splitURL[2] === 'kurpfalz1') {
		return 'brief';
	}
	else if (splitURL[2] === 'wuerttemberg1') {
		return 'brief';
	}
	else if (splitURL[2] === 'wuerttemberg2') {
		return 'brief';
	}
	else if (splitURL[2] === 'hauptpersonen') {
		return 'person';
	}		
    else return splitURL[2];
};

export default PassedComponent => {
    class SearchHOC extends Component {
		state = {
	            suche: this.props.match.url.split('/')[2].toLowerCase(),
				loading: false
	        }
	
        onKeyPressed = ({ key }) => {
            if (key === "Enter") this.props.resetPage()
        };

        reset = () => {
            this.props.resetItem();
            this.props.resetSearch();
        };
        
        componentDidMount() {
            if (this.state.suche === "kurpfalz1"){
                this.props.changeItem("band.v", "5fd20f9501d2af4cf55e459b");
                this.props.changeItem("aufnehmen.v", ["ja", "eher%20ja", "vielleicht", null, "ausstehend"]);
                this.props.setLimit(130);
            }
            if (this.state.suche === "strassburg1"){
                this.props.changeItem("band.v", "60022c7614a32a2868ff5a8e");
                this.props.changeItem("aufnehmen.v", ["ja", "eher%20ja", "vielleicht", null, "ausstehend"]);
                this.props.setLimit(130);
            }
            if (this.state.suche === "wuerttemberg1"){
                this.props.changeItem("band.v", "5fd210cb4725e94ea880e0a6");
                this.props.changeItem("aufnehmen.v", ["ja", "eher%20ja", "vielleicht", null, "ausstehend"]);
                this.props.setLimit(130);
            }
            if (this.state.suche === "wuerttemberg2"){
                this.props.changeItem("band.v", "620bf4d52d4f27168405a1d9");
                this.props.changeItem("aufnehmen.v", ["ja", "eher ja", "vielleicht", "ausstehend", null]);
                this.props.setLimit(130);
            }
            if (this.state.suche === "hauptpersonen"){
                this.props.changeItem("hauptfigur.hauptfigur.v", "ja");
                this.props.setLimit(220);
            }
        }
		
        componentWillUnmount = () => {
            this.reset();
        }
		
        render() {
            const { items, sort, asc, loading, match, count } = this.props;
            const kurpfalz1 = this.state.suche === "kurpfalz1";
            const strassburg1 = this.state.suche === "strassburg1";
            const wuerttemberg1 = this.state.suche === "wuerttemberg1";
            const wuerttemberg2 = this.state.suche === "wuerttemberg2";
            const hauptpersonen = this.state.suche === "hauptpersonen";
            return (
                <div style={{ width: '100%' }} onKeyDown={this.onKeyPressed}>
                    <ScrollContainer>
                        <Container>
                            <H2 id="Headline">
                            	{
									kurpfalz1 ? 'Kurpfalz I' 
									: strassburg1 ? 'Straßburg I'
									: wuerttemberg1	? 'Württemberg I'
									: wuerttemberg2	? 'Württemberg II'
									: hauptpersonen	? 'Hauptpersonen'										
									: typeParser(typeSlicer(match.url))
								}
							</H2>
                            <Row id="SearchBar" style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                <span id="SearchButtons" style={{ display: "flex" }}>
                                    <Link to={match.url} target="_self" color={defaultTextColor}>
										<Button id="SearchResetButton">
			                                Suche zurücksetzen
			                            </Button>
									</Link>
                                    <Button id="SearchButton" onClick={this.props.resetPage}>Suchen</Button>
                               </span>
                                {kurpfalz1 || strassburg1 || wuerttemberg1 || wuerttemberg2 || hauptpersonen ? (
									<ItemContainer>{'Es wurden ' + count + ' Einträge gefunden'}</ItemContainer>
								) : (
                                	<SearchFooter pos='top' startSearch={getDataFromApi}/>
                            	)}
                            </Row>
                            <PassedComponent
                               items={items}
                               loading={loading}
                               defaultSort={this.props.changeSort}
                               handleChangeSort={this.props.changeSortAndLoad}
                               sort={sort}
                               asc={asc}
                            />
                            {!kurpfalz1 && !strassburg1 && !wuerttemberg1 && !wuerttemberg2 && !hauptpersonen && count > 15 && (
                                   <SearchFooter startSearch={getDataFromApi}/>
                            )}
                        </Container>
                    </ScrollContainer>
                </div>
            );
        }
    }
    const mapStateToProps = (state) => ({
        items: state.search.items,
        sort: state.search.sort,
        asc: state.search.asc,
        loading: state.search.loading,
        count: state.search.count
    });

    const mapDispatchToProps = {
        changeSort,
		changeSortAndLoad,
        resetSearch,
        getDataFromApi,
        resetPage,
        setLimit,
        changeItem,
        resetItem,
        addNotification
    };
  
    return connect(mapStateToProps, mapDispatchToProps)(SearchHOC);
};