import React from 'react';
import { get } from '../../service/immutables';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { white } from '../../style/colors';

//TextareaAutosize kann nicht: width fit-content für die value-Länge.
//aber TextareaAutosize kann im Gegensatz zu Input auch Absätze wiedergeben.

const WhiteArea = styled('div')`
	background-color: ${white};
	width: ${props => {
        const { width } = props;
        let value = '800px'; // default
        if (width) value = width;
        return value;
    }};
	max-width: 85vw;
    height: fit-content;  
	margin: 5px 8px 6px 1px;
	padding: 11px 12px; 
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }
`;

const Text = styled(TextareaAutosize)`
	font-family: inherit;
	font-size: 13px;
    width: 100%;
    vertical-align: top;
    align-items: center;
    overflow: hidden !important;
    resize: none
`;

const LabelTextarea = ({
    value,
    erschlossen,
    unsicher,
    fragwuerdig,
	ca,
	width
}) => (
		<WhiteArea width={width}>
            <Text
				value={erschlossen ? (
						ca ? (
							unsicher ? (
								'[ca. ' + value + '?]'
							) : (
								'[ca. ' + value + ']'
							)
						) : (
							unsicher ? (
								'[' + value + '?]'
							) : (
								'[' + value + ']'
							)
						)
					) : (
						fragwuerdig ? (
							value + ' [?]'
						) : (
							value
						)
					)}
                disabled={true}
            />
		</WhiteArea>
);

LabelTextarea.defaultProps = {};

LabelTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any, //.isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

export default connect(
    mapStateToProps,
)(LabelTextarea);
