import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { grey, ultraLightGrey } from '../style/colors';
import { Row } from '../style/Row';
import Arrow from '../SVG/ChevronTopDown';

const BoldLabel = styled.div`
    display: flex;
    width: 100%;
    color: ${grey};
    font-weight: bold;
    font-size: ${p => (p.small ? '1rem' : '1.5rem')};
`;

const MarginBottom = styled.div`
    border-left: 5px solid ${ultraLightGrey};

    padding-left: 10px;
    margin-bottom: 15px;
`;

class Collapse extends Component {
    constructor(props) {
        super();
        this.state = {
            show: props.show,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.show || this.state.show !== nextState.show;
    }

    render() {
        const { children, label, small } = this.props;
        const { show } = this.state;
        return (
            <MarginBottom>
                <Row>
                    <BoldLabel small={small}>
                        {label}
                        <Arrow
                            direction={show}
                            onClick={() => this.setState(({ show }) => ({ show: !show }))}
                        />
                    </BoldLabel>
                </Row>
                {show && children}
            </MarginBottom>
        );
    }
}

Collapse.propTypes = {
    label: PropTypes.string,
    show: PropTypes.bool,
};

Collapse.defaultProps = {
    show: true,
};

export default Collapse;
