import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';
import RefsLinks from '../../container/RefsLinks';

import { BriefdruckRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';

import { FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchArchiv = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('druck.v.short.v');
    return (
        <>
            <div>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input width="100%" noMeta label="ID" name="briefDruckID" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('briefDruckID.v')}
                            direction={'briefDruckID.v' === sort ? !asc : false}
                            active={'briefDruckID.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Druck"
                            api="Druck"
                            value={get(search, 'druck') || ''}
                            name="druck"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('druck.v.short.v')}
                            direction={'druck.v.short.v' === sort ? !asc : false}
                            active={'druck.v.short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Blatt/Seitenzählung"
                            name="seite"
                            value={get(search, 'seite') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('seite.v')}
                            direction={'seite.v' === sort ? !asc : false}
                            active={'seite.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Nr."
                            name="nummer"
                            value={get(search, 'nummer') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('nummer.v')}
                            direction={'nummer.v' === sort ? !asc : false}
                            active={'nummer.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Digitalisat"
                            placeholder="http"
                            name="reproduktion.datei"
                            value={get(search, 'reproduktion.datei') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('reproduktion.datei.v')}
                            direction={'reproduktion.datei.v' === sort ? !asc : false}
                            active={'reproduktion.datei.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <div>zugehöriger Brief:</div>
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && !loading && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items && items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/briefdruck/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.briefDruckID && item.briefDruckID.v}</Cell>
                            <Cell>
                                <Link to={'/edit/druck/' + get(item, 'druck.v._id')}>
                                    {get(item, 'druck.v.short.v')}
                                </Link>
                            </Cell>
                            <Cell>{item.seite && item.seite.v}</Cell>
                            <Cell>{item.nummer && item.nummer.v}</Cell>
                            <Cell>
                                {item.reproduktion && item.reproduktion.map(e => (
                                    <>
                                        {get(e, 'datei.0.v') &&
                                        get(e, 'datei.0.v').includes('http') ? (
                                            <LinkExtern href={get(e, 'datei.0.v')} target="blank">
                                                <WebGlobeSVG />
                                            </LinkExtern>
                                        ) : (
                                            get(e, 'datei.v')
                                        )}
                                        <br />
                                    </>
                                ))}
                            </Cell>
                            <RefsLinks
                                label=""
                                api="brief"
                                query="druck.v="
                                idPath={get(item, '_id')}
                            />
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/briefdruck/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </div>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="briefdruck" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchArchiv);
