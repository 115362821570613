import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get as getLodash } from "lodash";

import { changeItem, resetItem } from '../../store/actions/item';
import { changeSort, changeSortAndLoad, resetSearch, getDataFromApi, resetPage, setLimit } from '../../store/actions/search';
import { addNotification } from '../../store/actions/notificationActions';

import ownSlice from '../../service/sliceSearch';

import SearchFooter from '../../components/SearchFooter';

import Area from '../../style/Area';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { defaultTextColor } from '../../style/colors';
import Button from '../../style/Button';
import Cell from '../../style/SearchCell';
import Container from '../../style/Container';
import Link from '../../style/Link';
import Loading from '../../components/Loading';
import OpenSVG from '../../SVG/Open';
import Row from '../../style/Row';
import Space from '../../style/Space';

import { FastSearch as Search, Input, Zeit } from '../../inputs/InputsWithLabelFocus';

import formatZeit from '../../service/formatZeit';

const SearchBody = styled('div')`	
 	display: flex;
 	align-items: center;
  	justify-content: center;
`;

const SearchTable = styled('div')`	
	display: table;
  	align-items: center;
  	justify-content: center;
	width: 925px;
`;

const SearchRowArea = styled(Area)`
    margin-top: 5px;
	margin-bottom: 6px;
	padding: 6px 8px;
	max-width: 100%;
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const TableRow = styled(SearchRowArea)` 
    grid-template-columns: 1.5em 7em 0.9fr 1.1fr 1.1fr 0.9fr 1fr 1fr;
    //                         Dat Ort   Verf  Empf  ZielTransReg
    display: grid;
`;

class SearchBrief extends React.Component {
     state = {
	            suche: this.props.match.url.split('/')[2].toLowerCase()
	        }
	
        onKeyPressed = ({ key }) => {
            if (key === "Enter") {
				this.props.resetPage();
			}
        };

        reset = () => {
            this.props.resetItem();
            this.props.resetSearch();
        };
	
        componentWillUnmount() {
           this.reset();
        };
        
        componentDidMount() {
            this.props.changeSort('datierung.shortZeit.v');
        }
        
        render() {
            const { items, loading, count, sort, asc, textSearch, regestSearch } = this.props;
            const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
            return (
				<SearchBody onKeyDown={this.onKeyPressed}>
                    <SearchTable>
		                <Container>
		                	<Row hcenter>
								<Area>
			                        <Input noMeta width="50px" label="Brief-ID" name="short" />
			                    </Area>
		                    	{loading === 0 && count === 0 && (
									!englroute ? (
										<Button id="SearchButton" onClick={this.props.resetPage} >
				                        	Suchen
				                        </Button>
			                        ) : (
										<Button id="SearchButton" onClick={this.props.resetPage} >
				                        	Search
				                        </Button>
									)
								)}
								{loading === 0 && count !== 0 && (
									!englroute ? (
										<Button id="SearchButton" onClick={this.props.resetPage} >
				                        	Suche aktualisieren
				                        </Button>
			                        ) : (
										<Button id="SearchButton" onClick={this.props.resetPage} >
				                        	Search
				                        </Button>
									)
								)}
			                    {loading === 0 && count !== 0 && (	
									<>
										{!englroute ? (
											<Link to={`/filter/brief`} target="_self" color={defaultTextColor}>
												<Button>
					                                zurücksetzen
					                            </Button>
											</Link>
										) : (
											<Link to={`/filter/brief/engl`} target="_self" color={defaultTextColor}>
												<Button>
					                                Reset
					                            </Button>
											</Link>
										)}
									</>
								)}
								{loading === 0 && (
									<>
										<Space/>
										{!englroute ? (
											<Link to={`/erweitert/brief`} target="_self" color={defaultTextColor}>
												<Button>
					                               Mehr Suchoptionen
					                            </Button>
											</Link>
										) : (
											<Link to={`/erweitert/brief`} target="_self" color={defaultTextColor}>
												<Button>
					                               Show Advanced search
					                            </Button>
											</Link>
										)}
									</>
								)}
                    		</Row>
			                <TableRow>
			                    <Cell block>{null}</Cell>
								{/*
			                    <Cell>
			                        <Input noMeta width="100%" label="ID" name="short" />
			                        {count !== 0 && (
										<ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('short.v')}
				                            direction={'short.v' === sort ? !asc : false}
				                            active={'short.v' === sort}
				                        />
									)}
			                    </Cell>
								*/}
			                    <Cell>
			                        <Zeit noMeta width="85%" label="Datum" name="datierung" noTimeFrame />
			                        {count !== 0 && (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('datierung.shortZeit.v')}
				                            direction={'datierung.shortZeit.v' === sort ? !asc : false}
				                            active={'datierung.shortZeit.v' === sort}
				                        />
									)}
			                    </Cell>
			                    <Cell>
			                        <Search
			                            noMeta
										api="Ort"
			                            label="Absendeort"
			                            name="absendeort.name"
										width="100%"
										placeholder="Ortsname"
										dontWantID
			                        />
			                        {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('absendeort.name.v')}
				                            direction={'absendeort.name.v' === sort ? !asc : false}
				                            active={'absendeort.name.v' === sort}
				                        />
			                        ) : (
										<Space/>
									)}
			                    </Cell>
			                    <Cell>
									<Search 
										noMeta 
										api="Zitiername"
										label="Verfasser"
										name="verfasser.nameMitAmt.combi" 
										width="100%"
										placeholder="Name/Amt"
										dontNeedID
										searchParam="nichtAuffuehren.brief.v=false"
									/>
			                         {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('verfasser.person.v')}
				                            direction={'verfasser.person.v' === sort ? !asc : false}
				                            active={'verfasser.person.v' === sort}
				                        />
			                        ) : (
										<Space/>
									)}
			                    </Cell>
			                    <Cell>
			                        <Search  
										noMeta
										api="Zitiername"
										label="Adressat"
										name="adressat.nameMitAmt.combi" 
										width="100%"
										placeholder="Name/Amt"
										dontNeedID
										searchParam="nichtAuffuehren.brief.v=false"
									/>
			                        {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('adressat.person.v')}
				                            direction={'adressat.person.v' === sort ? !asc : false}
				                            active={'adressat.person.v' === sort}
				                        />
			                        ) : (
										<Space/>
									)}
			                    </Cell>
			                    <Cell>
			                        <Search
										noMeta
										api="Ort"
			                            label="Zielort"
			                            name="zielortName"
										width="100%"
										placeholder="Ortsname"
										dontWantID
									/>
			                        {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('zielortName.v')}
				                            direction={'zielortName.v' === sort ? !asc : false}
				                            active={'zielortName.v' === sort}
				                        />
			                        ) : (
										<Space/>
									)}
			                    </Cell>
			                    <Cell>
			                        <Input 
										noMeta 
										label="Brieftext"
										name="transkription.volltext" 
										width="100%"
										placeholder="Text"
									/>
			                        {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('transkription.volltext.v')}
				                            direction={'transkription.volltext.v' === sort ? !asc : false}
				                            active={'transkription.volltext.v' === sort}
				                        />
			                         ) : (
										<Space/>
									)}
			                    </Cell>
			                    <Cell>
			                        <Input
			                            noMeta
			                            label="Regest"
			                            name="regest.text"
			                            width="100%"
										placeholder="Text"
			                        />
			                        {count > 1 ? (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('regest.text.v')}
				                            direction={'regest.text.v' === sort ? !asc : false}
				                            active={'regest.text.v' === sort}
				                        />
			                         ) : (
										<Space/>
									)}
			                    </Cell>
			                    {/* 
			                        <Cell>
			                            <Search
			                                noMeta
			                                width="100%"
			                                label="Sachen"
			                                api="Sache"
			                                name="schlagworte.sachen"
			                            />
			                            {count > 1 ? (
				                            <ArrowTopDown
				                                onClick={() => this.props.changeSortAndLoad('schlagworte.sachen.v')}
				                                direction={'schlagworte.sachen.v' === sort ? !asc : false}
				                                active={'schlagworte.sachen.v' === sort}
				                            />
				                         ) : (
											<Space/>
										)}
			                        </Cell> 
			                        */}
			                </TableRow>
			                <Loading loading={loading}>
			                    {items.map((item, i) => (
			                        <TableRow key={i}>
			                            <Cell>
											<Link to={`/ansicht/brief/${item._id}`} target="_blank">
				                                <OpenSVG />
				                            </Link>
										</Cell>
			                            {/*<Cell>{item.short && item.short.v}</Cell>*/}
		                            	<Cell>
											{item.datierung && `${formatZeit(getLodash(item, 'datierung'))}`}
										</Cell>
										<Cell block>
			                                {item.absendeort && item.absendeort.map((e, j) => (
			                                    <div key={i + " :1: " + j}>{getLodash(e, 'name.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell block>
			                                {item.verfasser && item.verfasser.map((verfasser, j) => (
			                                    <div key={i + " :2: " + j}>{getLodash(verfasser, 'person.v.short.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell block>
			                                {item.adressat && item.adressat.map((adressat, j) => (
			                                    <div key={i + " :3: " + j}>{getLodash(adressat, 'person.v.short.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell block>
			                                {item.zielortName && item.zielortName.map((e, j) => (
			                                    <div key={i + " :4: " + j}>{getLodash(e, 'v')}</div>
			                                ))}
			                            </Cell>
										{/*
			                            <Cell>
			                                {item.digitalisat.quelle.handschrift.v ||
			                                item.digitalisat.quelle.druck.v
			                                    ? '📷'
			                                    : ''}
			                            </Cell>
										*/}
			                            <Cell>
											{getLodash(item, 'transkription.volltext.v') && (
												textSearch && getLodash(item, 'transkription.volltext.v')
																.toLowerCase().search(textSearch.toLowerCase()) > 0 ? (
												<div>
													{getLodash(item, 'transkription.volltext.v')
														.toLowerCase().search(textSearch.toLowerCase()) > 0	&& (
															<>...</>
													)}
													<>
													{getLodash(item, 'transkription.volltext.v')
															.toLowerCase().search(textSearch.toLowerCase()) > 9 && (
																getLodash(item, 'transkription.volltext.v').slice(
																	getLodash(item, 'transkription.volltext.v')
																		.toLowerCase().search(textSearch.toLowerCase())-10, 
																	getLodash(item, 'transkription.volltext.v')
																		.toLowerCase().search(textSearch.toLowerCase())
																)
													)}
													</>
													<b>
													{getLodash(item, 'transkription.volltext.v').slice(
														getLodash(item, 'transkription.volltext.v')
															.toLowerCase().search(textSearch.toLowerCase()), 
														getLodash(item, 'transkription.volltext.v')
															.toLowerCase().search(textSearch.toLowerCase()) + textSearch.length
													)}
													</b>
													<>
													{getLodash(item, 'transkription.volltext.v').slice(
														getLodash(item, 'transkription.volltext.v')
															.toLowerCase().search(textSearch.toLowerCase()) + textSearch.length, 
														getLodash(item, 'transkription.volltext.v')
															.toLowerCase().search(textSearch.toLowerCase()) + (35 - textSearch.length)
													)}
													</>
													{getLodash(item, 'transkription.volltext.v')
														.toLowerCase().search(textSearch.toLowerCase()) > 0	&& (
															<>...</>
													)}
												</div>
												) : (
													<>{ownSlice(getLodash(item, 'transkription.volltext.v'), 35)}</>
												)
											)}
										</Cell>
			                            <Cell>
											{getLodash(item, 'regest.text.v') && (
												regestSearch && getLodash(item, 'regest.text.v')
																.toLowerCase().search(regestSearch.toLowerCase()) > 0? (
												<div>
													{getLodash(item, 'regest.text.v')
														.toLowerCase().search(regestSearch.toLowerCase()) > 0 && (
															<>...</>
													)}
													<>
													{getLodash(item, 'regest.text.v')
															.toLowerCase().search(regestSearch.toLowerCase()) > 9 && (
																getLodash(item, 'regest.text.v').slice(
																	getLodash(item, 'regest.text.v')
																		.toLowerCase().search(regestSearch.toLowerCase())-10, 
																	getLodash(item, 'regest.text.v')
																		.toLowerCase().search(regestSearch.toLowerCase())
																)
													)}
													</>
													<b>
													{getLodash(item, 'regest.text.v').slice(
														getLodash(item, 'regest.text.v')
															.toLowerCase().search(regestSearch.toLowerCase()), 
														getLodash(item, 'regest.text.v')
															.toLowerCase().search(regestSearch.toLowerCase()) + regestSearch.length
													)}
													</b>
													<>
													{getLodash(item, 'regest.text.v').slice(
														getLodash(item, 'regest.text.v')
															.toLowerCase().search(regestSearch.toLowerCase()) + regestSearch.length, 
														getLodash(item, 'regest.text.v')
															.toLowerCase().search(regestSearch.toLowerCase()) + (35 - regestSearch.length)
													)}
													</>
													{getLodash(item, 'regest.text.v')
														.toLowerCase().search(regestSearch.toLowerCase()) > 0 && (
															<>...</>
													)}
												</div>
												) : (
													<>{ownSlice(getLodash(item, 'regest.text.v'), 35)}</>
												)
											)}
										</Cell>
			                            {/*
		                                <Cell block>
		                                    {item.schlagworte.sachen.map((e, i) => (
		                                        <Link to={'/edit/sache/' + getLodash(e, 'v._id')} key={i}>
		                                            {get(e, 'v.short.v')}
		                                            <br />
		                                        </Link>
		                                    ))}
		                                </Cell>
			                            */}
			                        </TableRow>
			                    ))}
			                </Loading>
	                		<SearchFooter startSearch={getDataFromApi}/>                            
						</Container>
                    </SearchTable>
	            </SearchBody>
            );
        };
	}
	
   const mapStateToProps = (state) => ({
        items: state.search.items,
        sort: state.search.sort,
        asc: state.search.asc,
        loading: state.search.loading,
        count: state.search.count,
		regestSearch: state.item.regest && state.item.regest.text && state.item.regest.text.v,
		textSearch: state.item.transkription && state.item.transkription.volltext && state.item.transkription.volltext.v,
    });

    const mapDispatchToProps = {
        changeSort,
        changeSortAndLoad,
        resetSearch,
        getDataFromApi,
        resetPage,
        setLimit,
        changeItem,
        resetItem,
        addNotification
    };
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchBrief);