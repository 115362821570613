import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import { buildSache } from '../../store/actions/item';

import Anmerkung from '../../components/Anmerkungen';
import Switch from '../../components/Switch';

import CollapseArea from '../../container/CollapseArea';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Area from '../../style/Area';
import H1 from '../../style/H1';
//import H3 from '../../style/H3';
import H4 from '../../style/H4';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import ShortStampRed from '../../style/ShortStampRed';
import Space from '../../style/Space';
import { Row } from '../../style/Row';
import VerticalLine from '../../style/VerticalLine';
import OpenSVG from '../../SVG/Open';
import Update from '../../SVG/Play';

import {
    CheckBox,
    FastSearch as Search,
    Input,
	Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';
//import { LabelInput } from '../../labels/LabelWithNoFocus';
import wikipedia from '../../../assets/images/wikipedia.png';
import dbg from '../../../assets/images/dbg.jpg';


const Sache = ({ item, handleChange, type, short, typ, geprueft, uebergeordnet, erklaerungKurzGeprueft, buildShort, disableBegriff, disableErklaerung, shortGiven }) => (
    <>
        <Row>
            <H1>Sache</H1>
            <Space />
            <Link to={`/ansicht/sache/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
            <Space />
            <SetFavorite type={type} />
            <Space />
			<LinkExtern href={'https://de.wikipedia.org/w/index.php?search=' + short} target="_blank" rel="noopener noreferrer">
                <img src={wikipedia} width="100%" alt="" />
            </LinkExtern>
            <DeleteItem type={type} />
        </Row>
		<Row>
		 	{/*<H3>{typ && typ + ': '}</H3>*/}
			<Short />
			<Space />
			<H4 notBold>{get('geprueft.v', item) && <>✓</>}</H4>
			{short && (
				short.includes('1') || 
				short.includes('2') || 
				short.includes('3') || 
				short.includes('4') || 
				short.includes('5') || 
				short.includes('6') ||
				short.includes('7') || 
				short.includes('8') ||  
				short.includes('9')
				) && !typ && (
				<ShortStampRed>[Bitte Sachgruppe angeben!]</ShortStampRed>
			)}
		</Row>
        <CollapseArea label="Übergeordnete Sachen" show={true}>
            <Row>
                <List label="übergeordnete Sachen" name="uebergeordnet" simple>
                    {index => (
                        <Area noStyle>
                            <Search label="Sache" api="Sache" name={`uebergeordnet[${index}]`} noMeta width="170px" />
                        </Area>
                    )}
                </List>
            </Row>
        </CollapseArea>
        <CollapseArea label="Begriff" show={true}>
            <Row>
                <Area>
                    <Search api="Sachgruppe" label="Sachgruppe" name="typ" width="140px" noMeta />
                </Area>
				{typ === 'Drucktitel' && (
				<Area>
					<Row>	
                    	<Search label="Druck" api="Druck" name="zusammensetzung.werk.druck" noMeta />
                    	<Update onClick={buildShort} disabled={geprueft} />
					</Row>
                </Area>
				)}				
				<Area>
					<Row>	
	                    <Input label="Begriff" name="begriff" disabled={geprueft} medium noMeta />
						<VerticalLine />
	                    <CheckBox
	                        label="geprüft"
	                        name="geprueft"
	                        disabled={disableBegriff}
	                        width="40px"
							noMeta
							noUnderline
	                    />
					</Row>
                </Area>
				{(typ === 'Ereignis' || typ === 'Drucktitel' || typ === 'Werktitel/zitat') && (          
                <Area>
					<Row>
	                    <Zeit label="Zeit" noMeta width="180px" name={`zusammensetzung.zeit[0]`} />
	                    
	                    {/*<CheckBox label="Zeit anzeigen?" name="zusammensetzung.anzeigen" />*/}
                	</Row>
				</Area>
            	)}
				<Space />
				{get('typ.v', item) && get('typ.v', item).short.v === 'Bibelstelle' && (
					<LinkExtern href={'https://www.bibleserver.com/LUT/' + get('begriff.v', item)} target="_blank" rel="noopener noreferrer">
   						<img src={dbg} width="100%" alt="" />
					</LinkExtern>
				)}
            </Row>
			{shortGiven && (
			<>
				<Area>
		            <RefsCount
		                label="Verwendung in "
		                api="brief"
		                idPath="_id"
		                query="schlagworte.sachen.v="
		                anschlussText="Briefen"
		            />
				</Area>
				<CollapseArea show={false}>
	            	<RefsLinks api="brief" idPath="_id" query="schlagworte.sachen.v=" long column/>
				</CollapseArea>
			</>
			)}
        </CollapseArea>
		<CollapseArea label="Verweise" show={true}>
            <Row>
                <List label="Alternative Bezeichnungen" name="alternativen" simple>
                    {index => (
                        <Area noStyle>
                            <Switch name={`alternativen[${index}].show`}>
                                <Input
                                    label="Alternativbezeichnung"
                                    placeholder="Freitext"
                                    name={`alternativen[${index}].text`}
                                />
                                <Search
                                    label="s. auch"
                                    api="Sache"
                                    name={`alternativen[${index}].sache`}
                                />
                            </Switch>
                        </Area>
                    )}
                </List>
            </Row>
			{typ !== 'Bibelstelle' && (
			<Row>
                <Area>
                    <Row>
                        <Search label="s. Person" api="Zitiername" name="zusammensetzung.person" noMeta width="165px" />
                        {/*
                        <VerticalLine/>
                        <DropdownSimple
                            label="Anschluss"
                            name="zusammensetzung.anschlussPerson"
                            items={['Komma', 'Gen auf s', 'Leerzeichen']}
                            disableEmpty={false}
                            small
                            default_value="Komma"
                        />
                        */}
                    </Row>
                </Area>
                <Area>
                    <Row>
                        <Search label="s. Ort" api="Ort" name="zusammensetzung.ort" noMeta width="165px" />
                        {/*
                        <VerticalLine/>
                        <DropdownSimple
                            label="Anschluss"
                            name="zusammensetzung.anschlussOrt"
                            disableEmpty={false}
                            small
                            items={[
                                'von',
                                'in',
                                'zu',
                                'aus',
                                'nach',
                                'für',
                                'Gen auf s',
                                'Gen auf er',
                                'nur Ort',
                                'nur Kat.',
                                'Leerzeichen',
                                'auf der',
                            ]}
                            default_value="Gen auf er"
                        />
                     */}
                    </Row>
                </Area>
			</Row>)}
			{shortGiven && (
			<>
				<Area>Untergeordnete Sachen</Area>
				<CollapseArea show={false}>
		            <RefsLinks label="" api="sache" idPath="_id" query={'uebergeordnet.v='} column />
	        	</CollapseArea>
			</>
			)}
        </CollapseArea>
		<CollapseArea label="Sacherklärung" show={true}>
			<Area>
				<Row>
	                <Input label="Erklärung für Fußnoten" name="erklaerung.kurz" disabled={erklaerungKurzGeprueft} noMeta large />
					<Space />
					<CheckBox
                        label="fertig"
                        name="erklaerung.kurzGeprueft"
                        disabled={disableBegriff}
                        width="30px"
						noMeta
						noUnderline
                    />
                </Row>
				<List label="Autor" name="erklaerung.kurzAutor" simple>
                    {index => (
                        <Search
                            label="Autor"
                            name={`erklaerung.kurzAutor[${index}]`}
                            api="Mitarbeiter"
                            small
                        />
                    )}
                </List>
            </Area>
            <Area>
                <Anmerkung />
	            {/*
                <Textarea label="Längere Fassung / interne Notizen" name="erklaerung.lang" large noMeta />
                <List label="Autor" name="erklaerung.langAutor" simple>
                    {index => (
                        <Search
                            label="Autor"
                            name={`erklaerung.langAutor[${index}]`}
                            api="Mitarbeiter"
                            small
                        />
                    )}
                </List>
				*/}
            </Area>
        </CollapseArea>
			{/*
            <Row>
                <Area>
                    <DropdownSimple
                        label="Zählung"
                        name="zusammensetzung.zaehlung"
                        items={['', '1. ', '2. ', '3. ', '4. ', '5. ', '6. ', '7. ', '8. ', '9. ']}
                        disableEmpty={true}
                        width="155px"
                        default_value=""
                    />
                </Area>
            </Row>
            <Row>
                <Area>
                    <Input
                        label="Handschrift"
                        name="zusammensetzung.werk.freitext"
                        placeholder="Handschrift"
                    />
                </Area>
            </Row>*/}
    </>
);

const mapStateToProps = (state, props) => {
    let value = true;
    if (get('user.role', state.auth) === 'Admin') value = false;
    return {
		item: state.item,
		short: state.item.short ? state.item.short.v : '',
        geprueft: state.item.geprueft && state.item.geprueft.v,
		uebergeordnet: state.item.uebergeordnet && state.item.uebergeordnet[0] && state.item.uebergeordnet[0].v && state.item.uebergeordnet[0].v.short.v,
		typ: state.item.typ && state.item.typ.v && state.item.typ.v.short.v,
		erklaerungKurzGeprueft: state.item.erklaerung && state.item.erklaerung.kurzGeprueft && state.item.erklaerung.kurzGeprueft.v,
        disableBegriff: value,
		disableErklaerung: value,
        shortGiven: state.item.short && state.item.short.v ? true : false,
    };
};

const mapDispatchToProps = {
    buildShort: buildSache,
};

export default EditHOC(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Sache)
);
