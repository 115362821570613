import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { SacheRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import { CheckBox, FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchSache = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('begriff.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Begriff"
                            name="begriff"
                            value={get(search, 'begriff') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('begriff.v')}
                            direction={'begriff.v' === sort ? !asc : false}
                            active={'begriff.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox label="geprüft" name="geprueft" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geprueft.v')}
                            direction={'geprueft.v' === sort ? !asc : false}
                            active={'geprueft.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Alternative Bezeichnungen"
                            name="alternativen.sache"
                            placeholder="als eigene Sache"
                            api="Sache"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('alternativen.sache.v')}
                            direction={'alternativen.sache.v' === sort ? !asc : false}
                            active={'alternativen.sache.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Alternative Bezeichnungen"
                            placeholder="Freitext"
                            name="alternativen.text"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('alternativen.text.v')}
                            direction={'alternativen.text.v' === sort ? !asc : false}
                            active={'alternativen.text.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="übergeordnete Sache"
                            name="uebergeordnet"
                            api="Sache"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('uebergeordnet.v')}
                            direction={'uebergeordnet.v' === sort ? !asc : false}
                            active={'uebergeordnet.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Sachgruppe"
                            name="typ"
                            api="Sachgruppe"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('typ.v')}
                            direction={'typ.v' === sort ? !asc : false}
                            active={'typ.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && !loading && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/sache/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <Cell>{item.geprueft && item.geprueft.v ? '✔' : ''}</Cell>
                            <Cell block>
                                {item.alternativen && item.alternativen.map((e, i) => (
                                    <Link to={'/edit/sache/' + get(e, 'sache.v._id')} key={i}>
                                        {get(e, 'sache.v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell block>
                                {item.alternativen && item.alternativen.map((e, i) => (
                                    <>
                                        {get(e, 'text.v')}
                                        <br />
                                    </>
                                ))}
                            </Cell>
                            <Cell block>
                                {item.uebergeordnet && item.uebergeordnet[0] && item.uebergeordnet.map((e, i) => (
                                    <Link to={'/edit/sache/' + get(e, 'v._id')} key={i}>
                                        {get(e, 'v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell>
                                <Link to={'/edit/sachgruppe/' + get(item, 'sachgruppe.v._id')}>
                                    {get(item, 'typ.v.short.v')}
                                </Link>
                            </Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/sache/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="sache" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchSache);
