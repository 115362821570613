import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get as getLodash } from "lodash";
import { changeItem, resetItem } from '../../store/actions/item';
import { changeSort, changeSortAndLoad, resetSearch, getDataFromApi, resetPage, setLimit } from '../../store/actions/search';
import { addNotification } from '../../store/actions/notificationActions';

import SearchFooter from '../../components/SearchFooter';
import Area from '../../style/Area';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { defaultTextColor } from '../../style/colors';
import Button from '../../style/Button';
import Cell from '../../style/SearchCell';
import Container from '../../style/Container';
import Link from '../../style/Link';
import Loading from '../../components/Loading';
import OpenSVG from '../../SVG/Open';
import Row from '../../style/Row';
import Space from '../../style/Space';

import { Input, FastSearch as Search } from '../../inputs/InputsWithLabelFocus';

const SearchBody = styled('div')`	
 	display: flex;
 	align-items: center;
  	justify-content: center;
`;

const SearchTable = styled('div')`	
	display: table;
  	align-items: center;
  	justify-content: center;
	width: 900px;
`;

const SearchRowArea = styled(Area)`
    margin-top: 5px;
	margin-bottom: 6px;
	padding: 6px 8px;
	max-width: 100%;
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const TableRow = styled(SearchRowArea)`
    grid-template-columns: 2em 2fr 3fr 1fr 2em 1fr 2em 2fr 2em;
    //                        short alt Geb    Tod     Orte
    display: grid;
`;

class SearchPerson extends React.Component {
        state = {
	            suche: this.props.match.url.split('/')[2].toLowerCase(),
	        }
	
        onKeyPressed = ({ key }) => {
            if (key === "Enter") this.props.resetPage()
        };

        reset = () => {
            this.props.resetItem();
            this.props.resetSearch();
        };
	
        componentWillUnmount() {
           this.reset();
        };
        
        componentDidMount() {
            this.props.changeSort('shortAlt.v');
        }
        
        render() {
            const { items, loading, count, sort, asc } = this.props;
            const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
     		return (
                <SearchBody onKeyDown={this.onKeyPressed}>
					<SearchTable>
                    	<Container>
							<Row>
								{!englroute && (
									<Link to={`/erweitert/person`} target="_self" color={defaultTextColor}>
										<Button>
	                                        	Zur Erweiterten Personensuche
	                                    </Button>
									</Link>
								)}
								{englroute && (
									<Link to={`/erweitert/person/engl`} target="_self" color={defaultTextColor}>
										<Button>
	                                        	Go to advanced search mode
	                                    </Button>
									</Link>
								)}
								<Space/>
								{loading === 0 && count !== 0 && (	
									<>
									{!englroute ? (
										<Link to={`/filter/person`} target="_self" color={defaultTextColor}>
											<Button>
				                                Suche zurücksetzen
				                            </Button>
										</Link>
									) : (
										<Link to={`/filter/person/engl`} target="_self" color={defaultTextColor}>
											<Button>
				                                Reset
				                            </Button>
										</Link>
									)}
									</>
								)}
							</Row>
		                	<TableRow>
			                    <Cell block>{null}</Cell>
			                    <Cell>
	                   				<Input noMeta width="100%" label="Personenname" name="long" />
			                        {count !== 0 && (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('shortAlt.v')}
				                            direction={'shortAlt.v' === sort ? !asc : false}
				                            active={'shortAlt.v' === sort}
				                        />
			                        )}
	                    		</Cell>
                    			<Cell block>
			                    	{loading === 0 && count === 0 && (
										!englroute ? (
											<Button id="SearchButton" onClick={this.props.resetPage}>
					                        	Suchen
					                        </Button>
				                        ) : (
											<Button id="SearchButton" onClick={this.props.resetPage}>
					                        	Search
					                        </Button>
										)
									)}
									{loading === 0 && count !== 0 && (
										!englroute ? (
											<Button id="SearchButton" onClick={this.props.resetPage}>
					                        	aktualisieren
					                        </Button>
				                        ) : (
											<Button id="SearchButton" onClick={this.props.resetPage}>
					                        	Search
					                        </Button>
										)
									)}
								</Cell>
								<Cell>
	                   				<Input
			                            noMeta
			                            width="100%"
			                            label="Geburt"
			                            name="geburt.am.am.j"
			                        />
			                        {count !== 0 && (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('geburt.am.am.j.v')}
				                            direction={'geburt.am.am.j.v' === sort ? !asc : false}
				                            active={'geburt.am.am.j.v' === sort}
				                        />
			                        )}
	                    		</Cell>
	                    		<Cell block>{null}</Cell>
	                    		<Cell>
	                   				<Input
			                            noMeta
			                            width="100%"
			                            label="Tod"
			                            name="tod.am.am.j"
			                        />
			                        {count !== 0 && (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('tod.am.am.j.v')}
				                            direction={'tod.am.am.j.v' === sort ? !asc : false}
				                            active={'tod.am.am.j.v' === sort}
				                        />
			                        )}
	                    		</Cell>
	                    		<Cell block>{null}</Cell>
	                    		<Cell>
	                   				<Search
			                            noMeta
			                            width="100%"
			                            label="Wirkungsorte"
			                            api="Ort"
			                            name="aemter.ortName"
										placeholder="Ortsname"
										dontWantID
			                        />
			                        {count !== 0 && (
				                        <ArrowTopDown
				                            onClick={() => this.props.changeSortAndLoad('aemter.ort.v')}
				                            direction={'aemter.ort.v' === sort ? !asc : false}
				                            active={'aemter.ort.v' === sort}
				                        />
			                        )}
	                    		</Cell>
								<Cell block>{null}</Cell>
                			</TableRow>
			                <Loading loading={loading}>
			                    {items.map((item, i) => (
			                        <TableRow key={i}>
			                            <Cell>
				                            <Link to={`/ansicht/person/${item._id}`} target="_blank">
				                                <OpenSVG />
				                            </Link>
				                        </Cell>
			                            <Cell>
											{item.hauptfigur && item.hauptfigur.hauptfigur.v === 'ja' ? (                            	
				                            	<b>
				                            		{item.shortAlt && item.shortAlt.v}
				                            	</b>
			                            	) : (
				                            	<>
				                            		{item.shortAlt && item.shortAlt.v}
				                            	</>
			                            	)}
			                            </Cell>
										<Cell block>
			                                {item.namensvarianten && item.namensvarianten.map((object) => (
			                                   <div>{getLodash(object, 'short.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell>
				                            {item.geburt && item.geburt.am && item.geburt.am.am && item.geburt.am.am.j && item.geburt.am.am.j.v}
			                            </Cell>
			                            <Cell>{null}</Cell>
			                            <Cell>
				                            {item.tod && item.tod.am && item.tod.am.am && item.tod.am.am.j && item.tod.am.am.j.v}
			                            </Cell>
			                            <Cell>{null}</Cell>
			                            <Cell block>
			                                {item.aemter && item.aemter.map((object) => (
			                                   <div>
				                                   	{getLodash(object, 'ort.v.short.v') ? 
				                                   		getLodash(object, 'ort.v.short.v') + (getLodash(object, 'amt.v.short.v') ? 
				                                   			' (' + getLodash(object, 'amt.v.short.v') + ')' 
			                                   			: '') 
				                                   	: ''}
		                                   		</div>
			                                ))}
			                            </Cell>
			                            {/*
			                                <Cell>
			                                    <LinkExtern href={item.gnd.v}>
			                                        {get(item, 'gnd.v')
			                                            ? get(item, 'gnd.v')
			                                                  .replace('https://d-nb.info/gnd/', '')
			                                                  .replace('http://d-nb.info/gnd/', '')
			                                            : ''}
			                                    </LinkExtern>
			                                </Cell>
			                                */}
			                            <Cell>{null}</Cell>
			                        </TableRow>
			                    ))}
			                </Loading>
							<SearchFooter startSearch={getDataFromApi}/>
                    	</Container>
					</SearchTable>
                </SearchBody>
            );
        };
	}
	
   const mapStateToProps = (state) => ({
        items: state.search.items,
        sort: state.search.sort,
        asc: state.search.asc,
        loading: state.search.loading,
        count: state.search.count
    });

    const mapDispatchToProps = {
        changeSortAndLoad,
        changeSort,
        resetSearch,
        getDataFromApi,
        resetPage,
        setLimit,
        changeItem,
        resetItem,
        addNotification
    };
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchPerson);
