import React from 'react';
import styled from 'styled-components';
import Container from '../style/Container';
import ItemContainer from '../style/ItemContainer';
import Area from '../style/Area';
import Row from '../style/Row';
import Link from '../style/Link';
import { blue, grey, ultraLightGrey } from '../style/colors';
//import AllowedForUser from '../container/AllowedForUser';

//import background from '../../assets/images/background.jpg';
/*background-image: url(${background});*/
/*background-position: center;*/
/*background-attachment: scroll;*/

const WelcomeStyle = styled('div')`
    display: flex;
	align-items: center;
	justify-content: center;
    max-width: 100%;
    height: 100%;
`;

const ImageLinkLabel = styled('div')`
    height: 1.5em;
    font-weight: 500;
    font-size: 14px;
	padding-top: 8px;
    text-transform: uppercase;
	//background-color: #F7F7F7;
    color: ${blue};
    &:hover {
        color: ${grey};
    }
`;


const ImageLinkHeader = styled('div')`
    align-items: center;
	justify-content: center;
	text-align: center;
	margin-left: -9px;
	margin-top: -7px;
	border-bottom: 1px inset ${ultraLightGrey};
	background-color: rgba(0,0,0,.03);
    width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;


const ImageLinkBody = styled('div')`
    align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 10px;
	background-color: ${props => (props.color ? props.color : '')};
    width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

class ImageLink extends React.Component {
    render() {
        const { src, label, href, target } = this.props;
        return (
				<Link to={href} target={target}>
					<Area>
	                    <ImageLinkHeader height="29px" width="109%">
							<ImageLinkLabel to={href} >
		                        {label}
		                    </ImageLinkLabel>
	                    </ImageLinkHeader>
	                    <ImageLinkBody height="142px" width="195px" >
	                        <img src={src} width="100%" alt="" />
						</ImageLinkBody>
	                </Area>
				</Link>
        );
    }
}

class Welcome extends React.Component {
    render() {
        return (           
				<WelcomeStyle>
                    <Container>
							<br/><br/><br/><br/>							
							<ItemContainer>
		                        <Row center>		
		                            <ImageLink
										src="searchPerson.jpg"
										label="Suche"
										href="/filter/ort"
										target="_self"
									/>
		                            <ImageLink
		                                src="suedwestennah.jpg"
		                                label="Netzwerke"
		                                href="/karte"
										target="_self"
		                            />
		                        </Row>
							</ItemContainer>
                    </Container>
				</WelcomeStyle>
        );
    }
}

export default Welcome;
