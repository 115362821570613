import React, { Component } from 'react';
import { count, getItemLean } from '../service/fetch';
import Link from '../style/Link';
import { Row } from '../style/Row';
import { lightGrey } from '../style/colors';
import styled from 'styled-components';
import { get } from '../service/immutables';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Loading = styled(Row)`
    color: ${lightGrey} !important;
	display: flex;
	justify-content: center;
`;

class SachregisterUntergeordnet extends Component {
    search = async () => {
        //schlaue API lösung suchen --> gefunden
        let { api, query, idPath } = this.props;

        if (idPath === '') idPath = undefined;
        if (idPath !== undefined) {
            const countResult = await count(api, null, query + idPath);
            const result = await getItemLean(api, null, query + idPath + '&sort=datierung.shortZeit.v&asc=true&limit=' + countResult);
            this.setState({ items: result });
        } else {
            this.setState({ items: [] });
        }
        this.setState({ geladen: true });
    };

    constructor() {
        super();
        this.state = {
            items: [],
            geladen: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.idPath !== prevProps.idPath) {
            this.search();
        }
    }

    componentDidMount(props) {
        if (this.props.idPath !== '') {
            this.search();
        }
    }

    render() {
        const { items, geladen } = this.state;
        const { label, api, arrowDown, long } = this.props;
        return (
            <>
            {geladen === true ? ( /* Laden abgeschlossen */
				items.length > 0 && ( /* Es sind Elemente vorhanden */						
						<>
		                        {label && <>{label}<br /></>}
		                        {items.map((item, i) => (
		                            <div key={i}>
		                                <>
		                                    <Link target="_self" to={`/ansicht/${api}/${item._id}` + (this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? '/engl' : '')}>
		                                    	{arrowDown && <>▼ </>}
												{api === 'brief' && <>✉ </>}
		                                        {long === true ? (item.long.v) : (item.short.v)}
		                                    </Link>
		                                    
		                                </>
		                                <>&nbsp;</>
		                            </div>
		                        ))}
		                </>
						)	
				) : (/* geladen = false */
                    <Loading>... Liste wird geladen ...</Loading>
				)}						
            </>
        );
    }
}

SachregisterUntergeordnet.defaultProps = {};
SachregisterUntergeordnet.propTypes = {
    api: PropTypes.string.isRequired,
    idPath: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
};
const mapStateToProps = (state, props) => {
    let id = null;
    const reg = new RegExp('^[0-9a-fA-F]{24}$'); //for mongo-ID
    if (reg.exec(props.idPath)) {
        id = props.idPath;
    } else {
        id = get(props.idPath, state.item);
    }
    return { 
		idPath: id || props.helperForUserInput || '',
		path: state.router.location.pathname,
	};
};

export default connect(mapStateToProps)(SachregisterUntergeordnet);
